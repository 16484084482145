.internship {
  font-size: 11px;
  font-weight: 500;
  display: inline-block;
  padding: 5px 15px;
  border-radius: 50px;
  cursor: pointer;
  text-transform: uppercase;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  color: #c846ff;
  background: #f2d2ff;
}
.cl-internal-b3fm6y{
  display: none !important;
}
.internship:hover {
  box-shadow: 0 3px 9px rgba(96, 94, 94, 0.17);
}

.btn-xs {
  padding: 0.25rem 0.4rem;
  font-size: 0.875rem;
  line-height: 0.5;
  border-radius: 0.2rem;
}
.react-toast-notifications__container {
  z-index: 1000000 !important;
  margin: 0 40%;
}
.form-control,
.styled-select {
  height: 50px !important;
}
.search-job {
  border-radius: 25px;
}
@media (max-width: 991px) {
  .react-toast-notifications__container {
    margin: auto;
  }
}
