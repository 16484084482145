.carousel-container {
  display: flex;
}
.carousel-container > div {
  margin: auto;
}
.css-ohwg9z {
  /* width: 20%; */
  position: relative;
  overflow: hidden;
  margin: auto;
}
@media (max-width: 991px) {
  .css-ohwg9z {
    /* width: auto; */
  }
}
