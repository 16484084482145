/* Theme Color */
:root {
  --theme-color: #8546c9;
}

/* About Page Container */
.about-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: #f8f8f8;
  margin-top: 70px;
}

/* Company Logo */
.company-logo {
  width: 180px;
  height: 120px;
  object-fit: contain;
  margin-bottom: 20px;
  border-radius: 50%;
  padding: 10px;
}

/* Company Details */
.company-details {
  text-align: center;
}

.company-details h1 {
  font-size: 28px;
  color: #333;
}

.company-details h2 {
  font-size: 48px;
  color: #9d871b;
  margin-bottom: 10px;
}

.company-description {
  font-size: 18px;
  color: #777;
  max-width: 600px;
}

/* Founder Section */
.founder-section {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
}

.founder-section h2 {
  font-size: 36px;
  color: var(--theme-color);
  margin-bottom: 30px;
  width: 200px;
  margin: auto;
  /* margin-left: 10%; */
}

.founder-details {
  display: flex;
  align-items: center;
  justify-content: center;
}

.founder-img {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 30px;
  border: 4px solid #fff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.founder-description {
  text-align: left;
  max-width: 400px;
}

.founder-name {
  font-size: 24px;
  color: #333;
  margin-bottom: 5px;
}

.founder-role {
  font-size: 18px;
  color: var(--theme-color);
  margin-bottom: 10px;
}

.founder-bio {
  font-size: 18px;
  color: #777;
}
.founder-img {
  width: 150px;
  height: 150px;
}
.founder-text h3 {
  width: 83%;
}

@media (max-width: 768px) {
  .founder-text h3 {
    width: 100%;
    font-size: 28px;
  }
}
