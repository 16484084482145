.login-button > button{
  border: none;
    background: #f8f9fa;
}
.login-button > div{
  padding: 3px 7px;

}
.login-button:hover > button,.login-button:hover{
  background-color: #8546c9 !important;
  color: white !important;
}
.nav-link{
  color: black !important;
  border: 1px solid black;
  margin: 0 10px;
  padding: 5px;
}
.nav-link:hover {
  background-color: #8546c9 !important;
  color: white !important;
}

@media (max-width: 991px) {
  .theme-header {
    display: inline-block !important;
  }
  .navbar-toggler {
    display: inline-block;
  }
  .navbar-header {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    justify-content: space-between;
  }
  .navbar-collapse {
    width: 100%;
  }
  .margin-auto-to-all-child > li {
    margin: auto;
  }
}

@media (max-width: 1300px) {
  .theme-header {
  }
}
